/* Breakpoints
  ========================================================================== */
  $widescreen : 1020px;
  $desktop : 1020px;
  $laptop : 960px;
  $tablet : 500px;
  $phablet: 340px;
  $mobile : 180px;

@mixin respond-to($type){
  @if $type == mobile{
    @media only screen and (min-width: $mobile) and (max-width: $laptop) { @content; }
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
