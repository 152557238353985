// VARIABLES

$pad : 40px;
$fontsize: 30px;
$fontsize_mobile: 28px;
$bgcolor: rgba(255,255,255,1);
$stroke: rgba(220,220,220,1);
$color_text_light: rgba(0,0,0,0.8);
$color_body_dark: #000000;
$color_link_dark: #909090;
$color_text_dark: #F0F0F0;
$color_header_dark: #909090;
$color_stroke_dark: #555555;
$color_button_dark: #333333;

// MAIN BLOCKS

html {
  background-color: $bgcolor;
  @media (prefers-color-scheme: dark) {
    background-color: $color_body_dark;
  }
}

body {
  font-family: 'Readex Pro', sans-serif;
  display: flex;
  flex-direction: column;
  margin: none;
  padding: none;
  color: $color_text_light;
  background-color: $bgcolor;
  @media (prefers-color-scheme: dark) {
    background: $color_body_dark;
    color: $color_text_dark;
  }

}

main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  @include respond-to(mobile){
    flex-direction: column;
    margin: $pad;
  }
}

nav {
  display: flex;
  justify-content: space-between;
}

footer {
 position: fixed;
 width: 100%;
 display: flex;
 bottom: 0px;

}


// MAIN

.brand {
  flex-basis: 50%;
  padding: $pad 0 $pad $pad;

  @include respond-to(mobile){
    padding: 0px;
  }

  .logo {
    display:block;
    height:120px;
    width: 100px;
    background-size: 100%;
    position: fixed;
    background-image: url(/assets/images/yinglish_logo_2.png);
    @include respond-to(mobile){
      position:relative;
      height:100px;
      width: 80px;
    }
    @media (prefers-color-scheme: dark) {
      background-image: url(/assets/images/yinglish_logo_dark.png);

    }
  }


}


.content {
  flex-basis: 50%;
  flex-grow: 0;
  padding: $pad $pad $pad 0;

  @include respond-to(mobile){
    padding: $pad 0px 0px 0px;
  }

  a {
    color: rgb(25, 147, 226);
  }
}

.menu {
  list-style-type: none;
  display: flex;

  a{
    text-decoration: none;
  }

  .item {
    display: block;
    padding: 5px 10px;
    margin-right: $pad * 0.25;
    margin-bottom: $pad;
    background-color: rgba(0,0,0,0.05);
    border-radius: 9px;
    color: $color_text_light;

    @media (prefers-color-scheme: dark) {
      color: #fff;
      background-color: rgba(255,255,255,0.1);
    }

    &:hover {
      background-color: rgba(0,0,0,0.1);

      @media (prefers-color-scheme: dark) {
        background-color: $color_button_dark;
      }
    }

    & a{
      text-decoration: none;
    }

  }

  .selected{
    background-color: #000;
    color: #fff;

    @media (prefers-color-scheme: dark) {
      background-color: #fff;
      color: $color_text_light;
    }

    &:hover {
      background-color: #000;
      @media (prefers-color-scheme: dark) {
        background-color: #fff;
      }

    }
  }

}

.status{
  display: block;
  padding: 5px 0;
  margin-bottom: $pad;
  border-radius: 9px;
  line-height: 1.8em;
  &.open{
    color: rgb(0, 200, 86);
  }
  &.closed{
    color: rgb(250, 54, 54);
  }
  &.limited{
    color:rgb(255, 168, 0);
  }

  @include respond-to(mobile){
    position: fixed;
    top: 15px;
    right: 15px;
    padding: 0 10px;
    background-color: rgba(165, 235, 185, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    &.closed{
      background-color: rgba(237, 71, 71, 0.3);
    }
    &.limited{
      background-color: rgba(255, 174, 0, 0.3);
    }

  }
}

.description {
  font-size: $fontsize;
  line-height: 1.3em;

  @include respond-to(mobile){
    font-size: $fontsize_mobile;
  }

}

.matrix {
  display: flex;
  flex-direction: column;
  margin-top: $pad;
  margin-bottom: $pad;

  .row{
    display: flex;
    align-items: center;
  }

  .row.question {
    padding: 0 0 20px 0;
    color: rgba(0,0,0,0.4);
    @media (prefers-color-scheme: dark) {
      color: $color_header_dark;
    }
  }

  .row.answer{
    display: block;
    padding: 0 0 20px 0;
    margin-bottom: 20px;
    color: $color_text_light;
    line-height: 1.5em;
    @media (prefers-color-scheme: dark) {
      color: $color_text_dark;
    }

    & p{
      margin-bottom: 20px;
    }
  }

  .label {
    border-top: 1px solid $stroke;
    color: rgba(0,0,0,0.4);
    @media (prefers-color-scheme: dark) {
      color: $color_header_dark;
      border-top: 1px solid $color_stroke_dark;
    }
  }

  .col{
    flex-basis: 30%;
    padding: 20px 0;

    @include respond-to(mobile){
      flex-basis: 40%;
    }
  }

  .col.end{
    flex-basis: 70%;
  }

  .spacer {
    flex-basis: 3%;
  }
}

.title_label{
  padding-bottom: 8px;
}

.title_date {
  font-size: 0.8em;
}


.microcopy {
  padding: 20px 0 180px 0;
}

// FOOTER

.tab {
  margin-left: 50%;
  background-color: $bgcolor;
  width: calc(100%);

  @include respond-to(mobile){
    margin-left: $pad;
  }

  @media (prefers-color-scheme: dark) {
    background: $color_body_dark;
  }
}

.contact {
  margin: 0 $pad 0 0;
  padding: $pad * 0.7 0;
  border-top: 1px solid $stroke;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  @media (prefers-color-scheme: dark) {
    border-top: 1px solid $color_stroke_dark;
  }
}

#email {
  font-weight: 600;
}

.button {
  background-color: rgba(0,0,0,1);
  color: rgba(250,250,250,1);
  padding: 10px;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;

  &.copied {
    background-color: rgba(0,0,0,0);
    color: rgba(0,0,0,1)
  }

  @media (prefers-color-scheme: dark) {
    background-color: $color_button_dark;
  }

}
